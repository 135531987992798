.pay-lic-closing-btn {
  &:hover {
    box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.05), 0px 0px 0px 2px #ffffff,
      0px 0px 0px 4px #3b82f6;
    .pay-lic-closing {
      display: block;
    }
    .table-operation-btn {
      path {
        stroke: #3b82f6;
      }
      rect {
        fill: #3b82f6;
      }
    }
  }
}
