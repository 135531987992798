@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: tajawal !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*  */

.css-1uccc91-singleValue .select-container .select-value {
  display: none;
}

/*  */
html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}
/*  */
