// table wrapper
.ant-table-wrapper {
  border: 1px solid #cbd5e1;
}

// table head
thead {
  position: sticky;
  top: 0;
  z-index: 5;
}
.ant-table-thead > tr > th {
  background-color: #f8fafc;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #fafafa;
}

.ant-table table {
  table-layout: auto !important; // Fix empty data size
}


// table scroll bar
.ant-table-content {
  min-height: 600px;
  max-height: 600px;
  // height: 600px;
  overflow-y: visible;
  overflow-x: auto;
  overflow: -moz-scrollbars-vertical;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f8fafc;
    border-radius: 8px 0 0 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border: 1px solid #e7f5ff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
}

// table body row
.ant-table-row {
  background-color: #ffffff;
  max-height: 20px !important;
  height: 20px !important;
}
.ant-table-row:last-child .-top-8{
  top: -8.5rem ;
}
.ant-table-row:last-child #mail{
  position: absolute ;
  top: -1.5rem ;
}
// table row
tr {
  cursor: pointer;
  user-select: none;
}

// table body cell
.ant-table-cell {
  text-align: right;
  &::before {
    display: none;
  }
}

// table footer
.ant-table-footer {
  padding: 0;
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  right: 0;
}
.ant-pagination {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  position: relative;
}
li[title="Previous Page"] {
  position: absolute;
  left: 0;
  font-family: tajawal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
li[title="Next Page"] {
  position: absolute;
  right: 20px;
  font-family: tajawal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.ant-pagination-item {
  padding: 16px;
  border: none;
  background-color: inherit;
  font-family: tajawal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #cbd5e1;
}
.ant-pagination-item-active {
  color: #1e293b;
  border-top: 2px solid #1e293b;
  &:hover {
    border-color: #1e293b;
  }
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background-color: inherit;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background-color: inherit;
}
.ant-pagination-jump-next,
.ant-pagination-jump-next-custom-icon {
  position: relative;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item,
.ant-pagination-jump-next,
.ant-pagination-jump-next-custom-icon,
.ant-pagination-jump-prev,
.ant-pagination-jump-prev-custom-icon {
  height: unset;
}

// table sort icon
.ant-table-column-sorter,
.ant-table-column-sorter-full {
  display: none;
}

// table selection
tr.ant-table-row-selected td.ant-table-cell {
  background-color: #f8fafc;
}
tr.ant-table-row-selected {
  &:hover {
    td.ant-table-cell {
      background-color: #e5e7eb;
      background-color: rgba($color: #e5e7eb, $alpha: 0.4);
    }
  }
}

thead > tr > th.ant-table-cell.ant-table-selection-column {
  text-align: center;
}

//
//
// table typography
.ant-table-thead > tr > th.ant-table-cell {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: #1e293b;
  text-align: start;
  .col-title {
    margin-right: 8px;
    margin-left: 8px;
    width: max-content;
  }
}
tbody.ant-table-tbody tr.ant-table-row td.ant-table-cell {
  vertical-align: baseline;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #1e293bcc;
  .col-desc {
    margin-right: 20px;
  }
}

// table states
.table-states {
  height: 500px;
}
