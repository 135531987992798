.css-1uccc91-singleValue [aria-details="option-search-types-container"] {
  padding-right: 0;
}
.css-1uccc91-singleValue
  [aria-details="option-search-types-container"]
  [aria-details="option-search-types-layout"] {
  column-gap: 0;
}

.css-1rhbuit-multiValue {
  background-color: #e7f0ff !important;
  border: 1px solid #3b82f6;
  border-radius: 8px !important;
}
.css-1rhbuit-multiValue [aria-details="option-search-result-container"] {
  padding: 0;
}
.css-1rhbuit-multiValue
  [aria-details="option-search-result-container"]
  [aria-details="option-search-result-layout"] {
  column-gap: 0;
  display: none;
}
.css-1rhbuit-multiValue svg {
  display: none;
}
.css-xb97g8 svg {
  display: block;
}

.css-xb97g8 {
  cursor: pointer;
  &:hover {
    background-color: unset !important;
    color: unset !important;
  }
}
