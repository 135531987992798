*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}
body {
  background-color: white;
}

/*  */
/*  */
/*  */

.tool-pin-container {
  &:hover {
    .tool-pin {
      display: block;
    }
  }
}

.ReactCollapse--collapse {
  transition: all 0.3s;
}

/*  */
/*  */
/*  */

// .custom-grid-1 {
//   @media (min-width: 568px) {
//     display: grid;
//     grid-template-columns: 1fr 310px;
//     gap: 16px;
//   }
// }

.scroll-bar {
  overflow: overlay;
}
.scroll-bar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scroll-bar::-webkit-scrollbar-track {
  background: #f8fafc;
  border-radius: 8px 0 0 8px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border: 1px solid #e7f5ff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

//
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #d1fae5 !important;
  color: #10b981 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #ffd4d4 !important;
  color: #dc2626 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: #fff1d5 !important;
  color: #eb9b54 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  background-color: #e7f0ff !important;
  color: #3b82f6 !important;
}

.Toastify__toast-icon {
  margin: 0 !important;
}

.Toastify__toast-body {
  display: flex !important;
  justify-content: space-between !important;
  column-gap: 16px !important;
  margin: 0 !important;
  padding: 0 !important;
}
.Toastify__toast {
  display: flex !important;
  justify-content: space-between !important;
  column-gap: 16px !important;
  font-family: Tajawal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  padding: 0 16px !important;
  width: 450px !important;
}

.Toastify__toast-container {
  width: 450px !important;
}
.change-language {
  width: 100%;
  height: auto;
  padding: 7px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  color: rgb(74, 74, 255);
}
