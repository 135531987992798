.close-details-container {
  width: 850px;
  max-width: 850px;
  max-height: 85vh;
  @media (max-width: 960px) {
    width: 85vw;
    max-width: 85vw;
  }
  @media (max-width: 640px) {
    width: 92vw;
    max-width: 92vw;
  }
}
