.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  transition-timing-function: ease-out;
  animation-name: overlay;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.overlay--side {
  justify-content: end;
}

.modal {
  /* width: 40rem;
  max-height: 60vh; */
  overflow: auto;
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  transition: all 3s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-name: modal;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.modal--side {
  overflow: overlay;
  width: 50%;
  height: 100%;
  border-radius: unset;
  animation-name: modal--side;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.modal::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.modal::-webkit-scrollbar-track {
  background: #f8fafc;
  border-radius: 8px 0 0 8px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border: 1px solid #e7f5ff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

@keyframes overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal--side {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0;
  }
}
