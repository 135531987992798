.notifications-scrollbox {
  overflow: auto;
  visibility: hidden;
  scrollbar-width: thin;
  scrollbar-color: #2d2d2d #e6f5ff;
  height: 450px;
  min-height: 450px;

  &::-webkit-scrollbar {
    width: 0.7rem;
  }

  &::-webkit-scrollbar-track {
    background: #e6f5ff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2d2d2d;
    border: 1px solid #e6f5ff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  &:hover,
  &:focus {
    visibility: visible;
  }
}

.notifications-container {
  visibility: visible;
  max-height: 28rem;
}

.notifications-card {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  &:hover {
    background: rgba(22, 151, 255, 0.05);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }
}

.bill-circle {
  width: 35px;
  height: 35px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.bill-circle-active, .bill-circle:hover {
  background-color: #E7F0FF;
}
.notificationsCount {
  background-color: red;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-viewed-notification {
  background: rgba(22, 151, 255, 0.05);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;  
}
.notification-header-en {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem .5rem;
}