.add-tools {
  border: 1px solid #dedede;
  background: #f9faff;
  width: 316px;
  height: 56px;
  font-weight: 400;
  font-size: 16px;
  font-family: "Almarai", sans-serif;
  padding: 10px;
  /* margin-bottom: 28px; */
  margin-bottom: 5px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.home .register-process .add-tools {
  border: 1px solid #dedede;
  background: #f9faff;
  width: 316px;
  height: 56px;
  font-weight: 400;
  font-size: 16px;
  font-family: "Almarai", sans-serif;
  padding: 10px;
  /* margin-bottom: 28px; */
  margin-bottom: 5px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.react-tag-input {
  font-weight: 400;
  font-size: 16px;
  font-family: "Almarai", sans-serif;
  //   min-height: 56px;
  display: flex; ////
  flex-wrap: wrap; ////
  //   width: 720px;
  border: 1px solid #dedede;
  background: #f9faff;
  padding: 4px 4px 8px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  overflow: auto;
} /* Wrapper */

.react-tag-input__input {
  font-size: 14px;
  font-family: "Almarai", sans-serif;
  border: none !important;
  outline: none;
  width: 100%;
  min-height: 100px;
} /* Inner input */

.react-tag-input__tag {
  background: #1697ff;
  border-radius: 8px;
  padding: 0 20px;
  margin-left: 8px;
  margin-top: 4px;
  display: flex;
} /* Tag wrapper */

.react-tag-input__tag__content {
  color: #fff;
  font-size: 14px;
  font-family: "Almarai", sans-serif;
} /* The text content within the tag */

.react-tag-input__tag__remove {
  border-radius: 50%;
  color: #1697ff;
  background: #fff;
  margin-right: 5px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
}

// .react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background: #fff;
  content: "x";
}

.disabled {
  background-color: #cccccc !important;
  cursor: not-allowed;
}

.request_for_access_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.request_for_access_footer {
  width: 100%;
  height: auto;
  justify-self: flex-end;
  margin-top: auto;
  border-top: 1px solid #CBD5E1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.request_for_access_btn {
  background: #FFFFFF;
  border: 1px solid #CBD5E1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #1E293B;
  padding: 5px 15px;
  margin: 0px 7px;
}
.request_for_access_send_btn {
  width: auto;
  height: auto;
  margin-right: 10px;
  background: #FFFFFF;
  border: 1px solid #CBD5E1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1E293B;
  padding: 0px 15px;
  height: 40px;
}

.btn_disabled {
  background: #CBD5E1;
  color: #94A3B8;
  cursor: not-allowed;
}
.select_section_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}
.select_section_header {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #1E293B;
  margin-bottom: 5px;
}
.select_section_header_hint {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #6B7280;  
}
.select_section_body {
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  border: 1px solid #CBD5E1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.select_section_chip {
  background: #F8FAFC;
  border-radius: 10px;
  width: auto;
  display: flex;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 5px;
}

.select_section_chip_selected {
  background: #E7F0FF;  
  border: 1px solid #3B82F6;
  border-radius: 10px;
}
.request_access_success_screen {
  background-image: linear-gradient(180deg, #3B82F6 0%, #0A46BA 100%);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
}
.request_access_success_screen_image_container {
  width: 50%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.request_access_success_screen_text_container {
  flex: 1;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.request_access_image {
  background-image: linear-gradient(180deg, #3B82F6 0%, #0A46BA 100%);
  width: 40%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
}
.request_access_imageContainer {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.request_access_image_text_container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}
.request_access_image_img {
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 90%;
}