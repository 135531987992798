.TableMenuContainer {
  position: relative;
  overflow: hidden;
}
.TableMenuButton {
  background: #F8FAFC;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 15px;
}
.TableMenu {
  position: fixed;
  width: 224px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  flex-direction: column;
  display: none;
  z-index: 90000000;
  overflow: hidden;
}
.TableMenuOption {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  overflow: hidden;
}
.TableMenuOption:hover {
  background: #F8FAFC;
}
.TableMenuOptionDisabled {
  opacity: 0.5;
}
.TableMenuIcon {
  margin: 0px 7px;
}
.TableMenuText {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #1E293B;
}
.hideTableMenuButton {
  opacity: 0;
}
.EmptyTableMenu {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}