.add-safe-container {
  width: 100%;
  padding: 20px;
}
.usersContainerEdit {
  max-width: 700px;
  padding: 20px;
  border: 1px solid #CBD5E1;
  border-radius: 6px;
  margin: 10px;
  max-height: 400px;
  min-height: 300px;
}
.usersContainerHeader {
  width: auto;
  height: auto;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.AddSafeFormSecondaryText {
  font-size: 12px;
  color: #6B7280
}
.AddSafeInputRow {
  width: 100%;
  max-width: 700px;
  margin: 20px 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addSafeInputHalf {
  width: 50%
}
.css-g1d714-ValueContainer {
  display: none;
}
.fixedFooter {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #CBD5E1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
