.safes_header {
    width: 100%;
    border: 1px solid #cbd5e1;
    border-bottom: none;
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 10px;
    box-shadow: 0px 0px 8px rgba(169, 181, 190, 0.3);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.safes-header-section {
    width: 100%;
    background-color: #fff;
    height: 100px;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #cbd5e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.safes-header-section-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.safes-header-section-balance {
    font-weight: 500;
    font-size: 28px;
    color: #1E293B;
}
.safes-header-small-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #94A3B8;
}
