.chart-container {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #cbd5e1;
    box-shadow: 0px 0px 32px #EEF1FF;
    -webkit-box-shadow: 0px 0px 32px #EEF1FF;
    -moz-box-shadow: 0px 0px 32px #EEF1FF;
}
.chart-header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.chart-body {
    width: 100%;
}
.chart-options {
    display: flex;
    width: auto;
    height: auto;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}
.chart-option {
    width: auto;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chart-option-text {
    font-size: 12px;
    font-weight: 600;
    color: #6B7280;
    margin: 0px 5px;
}
.chart-date {
    width: auto;
    height: auto;
    position: relative;
    cursor: pointer;
}
.date-picker {
    width: auto;
    height: auto;
    background-color: #fff;
    padding: 20px;
    position: absolute;
    z-index: 2000;
    border: 1px solid #cbd5e1;
    box-shadow: 0px 0px 15px #EEF1FF;
    -webkit-box-shadow: 0px 0px 15px #EEF1FF;
    -moz-box-shadow: 0px 0px 15px #EEF1FF;
    border-radius: 16px;
    top: 100%;
}
.chart-date-text {
    font-weight: 600;
    font-size: 12px;
    color: #3B82F6;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-tooltip {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #6B7280;
    box-shadow: 0px 12px 32px rgba(107, 114, 128, 0.5);
    -webkit-box-shadow: 0px 12px 32px rgba(107, 114, 128, 0.5);
    -moz-box-shadow: 0px 12px 32px rgba(107, 114, 128, 0.5);
    border-radius: 8px;
    padding: 10px 25px;
    color: #fff;
}
.custom-tooltip-label {
    color: #bac0cc;
    font-weight: 700;
    margin-bottom: 5px;
}
.custom-tooltip-item-list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 5px 0px;
}
.custom-tooltip-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.custom-tooltip-item-color-id {
    width: 5px;
    height: 10px;
    border-radius: 5px;
    margin: 0px 5px;
}
.custom-tooltip-item-name {
    color: #fff;
    font-size: 12px;
    margin: 0px 5px;
}
.custom-tooltip-item-value {
    color: #fff;
    font-size: 12px;
}