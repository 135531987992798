.active-money-requests {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.active-money-requests-table {
    transition: height .3s ease-out;
}
.active-money-requests-table::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
}
.active-money-requests-table::-webkit-scrollbar-track {
    width: 5px;
    background-color: #fff;
    border-radius: 10px;
}
.active-money-requests-more {
    width: 100%;
    height: auto;
    padding: 10px;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #3F84F6; 
    background-color: #fff;   
    border: 1px solid #CBD5E1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
}
.ant-table-content {
    padding-bottom: 60px;
}