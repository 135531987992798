.roles-dropdown {
    width: 240px;
    height: 238px;
    background-color: #fff;
    position: absolute;
    z-index: 2000;
    top: 100%;
    right: 0;
    display: none;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    overflow-x: auto;
}
.roles-dropdown::-webkit-scrollbar {
    width: 8px;
    height: 7px;
}

.roles-dropdown::-webkit-scrollbar-track {
    background: #f8fafc;
    border-radius: 5px 0 0 5px;
}

.roles-dropdown::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border: 1px solid #e7f5ff;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.roles-dropdown-parent {
    width: 55px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
  
.roles-dropdown-parent:hover .roles-dropdown {
    display: flex;
    flex-direction: column;
}
.roles-dropdown-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 10px 5px;
}
.roles-dropdown-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #1E293B;
    padding: 0px 5px;
}

.row-text {
    font-size: 14px;
    color: #1E293B
}
.animatedActiveSafe {
    animation-name: activeSafeAnimation;
    animation-duration: 10s;
    animation-iteration-count: 1;
    animation-direction: ease-out;
}
@keyframes activeSafeAnimation {
    0%   {background-color: #d6d6d6;}
    25%  {background-color: #e0e0e0;}
    50%  {background-color: #f1f1f1;}
    100% {background-color: #fff;}
}