.latest-container {
    background-color: #fff;
    border: 1px solid #CBD5E1;
    box-shadow: 0px 0px 32px #EEF1FF;
    -webkit-box-shadow: 0px 0px 32px #EEF1FF;
    -moz-box-shadow: 0px 0px 32px #EEF1FF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: height .3s ease-out;
}
.latest-container-header {
    width: 100%;
    height: 50px;
    min-height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(169, 181, 190, 0.3);
    -webkit-box-shadow: 0px 0px 8px rgba(169, 181, 190, 0.3);
    -moz-box-shadow: 0px 0px 8px rgba(169, 181, 190, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}
.latest-container-header-right, .latest-container-header-left {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
}
.latest-container-header-right {
    justify-content: flex-start;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: right;
    color: #6B7280;
}
.latest-container-header-left {
    justify-content: flex-end;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #6B7280;
}
.latest-container-body {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.latest-container-body-scrollable {
    overflow-y: auto;
    padding-bottom: 40px;
}
.latest-container-body-scrollable::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
}
.latest-container-body-scrollable::-webkit-scrollbar-track {
    width: 5px;
    background-color: #fff;
    border-radius: 10px;
}
.latest-container-body-scrollable::-webkit-scrollbar-thumb {
    width: 5px;
    background: #ececec;
    border-radius: 10px;
}
.latest-container-footer {
    width: 100%;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    border-top: 1px solid #CBD5E1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3F84F6;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
}
.pending-row {
    width: 100%;
    height: 60px;
    min-height: 60px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.pending-row:hover {
    background-color: #f9f9f9;
}
.loading-circle-container {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-circle-container img {
    width: 30px;
    height: 30px;
}
.pending-row-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
}
.pending-row-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0px 5px;
}
.pending-row-name {
    font-size: 12px;
    font-weight: 400;
    color: #6B7280;
}
.pending-row-safe-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background: #F8FAFC;
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #6B7280;
}
.pending-row-category {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background: #F8FAFC;
    border-radius: 10px;
}
.pending-row-amount {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #1E293B;
}
.pending-row-date {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #94A3B8;
}
.total-section {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
}
.total-section-header {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: flex-start;
    position: relative;
}
.total-section-filter-btn {
    display: flex;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #3B82F6;
    background-color: #E7F0FF;
    border: 1px solid #3B82F6;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 7px;
    cursor: pointer;
}
.total-filter-btn {
    display: flex;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: #6B7280;;
    background-color: #fff;
    border: 1px solid #CBD5E1;;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}
.totals-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: auto;
}
.total-container {
    width: 33%;
    height: auto;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #CBD5E1;
    box-shadow: 0px 0px 32px #EEF1FF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    transition: height .3s ease-out;
}
.total-container .total-container-header {
    width: 100%;
    height: 150px;
    background: #FFFFFF;
    box-shadow: 0px 0px 32px rgba(238, 241, 255, 0.5);
    border-radius: 16px;
    padding: 5px;
}
.total-container .total-container-header .total-container-header-info {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.total-container .total-container-header .total-container-header-info .total-container-header-info-text {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.total-container-info-text-header {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: right;
    color: #6B7280;
    margin: 3px 0px;
}
.total-container-info-text-amount {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #1E293B;
}
.total-container-info-text-total {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #6B7280;
}
.total-container-transactions {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 32px rgba(238, 241, 255, 0.5);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: height .3s ease-out;
}
.total-container .total-container-transactions .transactions-container {
    width: 100%;
    flex: 1;
}
.total-container .total-container-transactions .total-container-transactions-more {
    width: 100%;
    height: auto;
    padding: 10px;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #3F84F6; 
    background-color: #fff;   
    border-top: 1px solid #CBD5E1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
}
.total-container-transactions-title {
    width: 100%;
    height: auto;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #6B7280;
    border-bottom: 1px solid #CBD5E1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

.total-container .total-container-chart {
    width: 100%;
    height: 350px;
    background: #FFFFFF;    
    box-shadow: 0px 0px 32px #EEF1FF;
    border-radius: 16px;
    padding: 10px;
}
.total-section-date-picker {
    width: auto;
    height: auto;
    background-color: #fff;
    padding: 20px;
    position: absolute;
    z-index: 2000;
    border: 1px solid #cbd5e1;
    box-shadow: 0px 0px 15px #EEF1FF;
    -webkit-box-shadow: 0px 0px 15px #EEF1FF;
    -moz-box-shadow: 0px 0px 15px #EEF1FF;
    border-radius: 16px;
    top: 100%;
    right: 50px;
}
.total-container-header-stat {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.total-container-header-stat .stat {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.total-container-scrollable {
    overflow-y: auto;
    padding-bottom: 40px;
}
.total-container-scrollable::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
}
.total-container-scrollable::-webkit-scrollbar-track {
    width: 5px;
    background-color: #fff;
    border-radius: 10px;
}
.total-container-row {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    cursor: pointer;
}
.total-container-row:hover {
    background-color: #f9f9f9;
}
.total-container-row-user {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}
.total-container-row-user-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 5px;
}
.total-container-row-user-name {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #6B7280;
}
.total-container-row-category {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.total-container-row-category span {
    width: auto;
    height: auto;
    background: #F8FAFC;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #6B7280;
}
.total-container-row-amount {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
}
.total-container-row-amount .amount {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;    
    display: flex;
    align-items: center;    
    color: #1E293B;
}
.total-container-row-amount .date {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #6B7280;
}
.total-container-chart-container {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.total-container-chart-header {
    width: 100%;
    height: auto;
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #6B7280;
}

.total-container-chart-footer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
}
.total-container-chart-footer .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.total-container-chart-footer .circle {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-left: 5px;
}
.total-container-chart-footer .name {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9C9C9C;
}
.last-updates-row {
    width: 100%;
    min-height: 60px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
}
.last-updates-row-data {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
}
.last-updates-row-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.last-updates-row-text {
    flex: 1;
    max-width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
    overflow: hidden;
}
.last-updates-row-title {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    color: #111827;
}
.last-updates-row-description {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #6B7280;
    padding: 5px 0px;
    max-width: 100%;
}
.last-updates-row-date {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #6B7280;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.last-updates-row-highlight {
    font-weight: 700;
    margin: 0px 4px;
}
.user-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0px 5px;
    background-color: #1E293B;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}