.tabs .tab {
  position: relative;
  display: inline-block;
}

.tabs .tab::after {
  content: "";
  background: #3b82f6;
  height: 2px;
  position: absolute;
  bottom: 0;
  transition: 0.15s all 0.15s;
}

.tabs .tab::after {
  left: 100%;
  right: 0;
}

.tabs .tab:hover ~ .tab::after {
  left: 0;
  right: 100%;
}

.tabs .tab:hover::after {
  left: 0;
  right: 0;
}

.tabs .tab:active ~ .tab::after {
  left: 0;
  right: 100%;
}

.tabs .tab:active::after {
  left: 0;
  right: 0;
}
