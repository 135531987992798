.balance-details-card {
    width: 32%;
    height: 150px;
    background-color: #fff;
    box-shadow: 0px 0px 32px #EEF1FF;
    -webkit-box-shadow: 0px 0px 32px #EEF1FF;
    -moz-box-shadow: 0px 0px 32px #EEF1FF;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.blue-border {
    border: 1px solid #59A0FF;    
}
.red-border {
    border: 1px solid #FF5858;
}
.orange-border {
    border: 1px solid #FFB972;
}
.balance-details-card-num {
    font-weight: 700;
    font-size: 36px;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.balance-details-card-text {
    font-weight: 700;
    font-size: 16px;
    color: #6B7280;
}