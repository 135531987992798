.floatingButtons {
    position: absolute;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.floatingButton {
    padding: 7px;
    margin-right: 5px;
    font-size: 12px;
    background-color: #F8FAFC;
    border-radius: 6px;
}
.subActive {
    background-color: #E7F0FF;
    color: #3B82F6;
}

.responsiveBtn {
    display: flex;
    justify-content: center;
}

.disabledButton {
    color: rgba(177, 177, 177, 0.6);
}
.headerSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
}
.searchContainer {
    width: 390px;
    height: auto;
}
.doesNotNeedPermission {
    position: absolute;
    left: -30%;
    width: auto;
    white-space: nowrap;  
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #94A3B8;
    background: #F8FAFC;
    border-radius: 10px;
    margin-right: 5px;
    padding: 5px;
}
.safes-header-section {
    width: 100%;
    background-color: #fff;
    height: 100px;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #cbd5e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media only screen and (max-width: 1065px) {
    .responsiveBtn {
        display: flex;
        justify-content: flex-start;
        padding-right: 10px;
    }
}