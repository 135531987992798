textarea {
    border: 2px solid #000;
}

.container {
    width: 100vw;
    background-color: #fff;
}

.react-grid-item {
    background-color: rgb(236, 236, 236);
}

.recharts-wrapper {
    cursor: inherit !important;
}
.layout {
    height: 350px;
}
.drag-area {
    cursor: grab;
    display: none;
}
.react-draggable-dragging .drag-area {
    cursor: grabbing;
    display: none
}

.react-resizable-handle, .react-resizable-handle-se {
    display: none;
}
.scrollableChart::-webkit-scrollbar {
    height: 10px;
    background-color: #fff;
}
.scrollableChart::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
}
.react-grid-item {
    transform: translate(0px, 0px) !important;
    height: 340px !important;
    background-color: #fff !important;
}
.react-grid-item::before {
    content: none;
}
.react-grid-item::after {
    content: none;
}
::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 10px;
}
.recharts-default-tooltip {
    background-color: #6B7280 !important;
    box-shadow: 0px 12px 32px rgba(107, 114, 128, 0.5) !important;
    -webkit-box-shadow: 0px 12px 32px rgba(107, 114, 128, 0.5) !important;
    -moz-box-shadow: 0px 12px 32px rgba(107, 114, 128, 0.5) !important;
    border-radius: 8px !important;
    padding: 10px 30px !important;
    color: #fff !important;
}
.recharts-tooltip-label {
    color: #bac0cc !important;
    font-weight: 700 !important;
    margin-bottom: 5px !important;
}
.recharts-tooltip-item-list {
    color: #fff !important;
}
.recharts-tooltip-item {
    color: #fff !important;
}
.recharts-tooltip-item-name {
    color: #fff !important;
}
.recharts-tooltip-item-separator {
    color: #fff !important;
    margin: 0px 5px !important;
}
