.pay-lic-accept-btn {
  position: relative;
  width: 12px;
  overflow: hidden;
  &:hover {
    width: 46px;
  }
}
.pay-lic-accept {
  left: 3px;
}

.pay-lic-reject-btn {
  position: relative;
  width: 12px;
  overflow: hidden;
  &:hover {
    width: 48px;
  }
}
.pay-lic-reject {
  left: 0;
}

.pay-lic-redirect-btn {
  position: relative;
  width: 12px;
  overflow: hidden;
  &:hover {
    width: 76px;
  }
}
.pay-lic-redirect {
  left: 0;
}

.pay-lic-poke-btn {
  position: relative;
  width: 24px;
  overflow: hidden;
  &:hover {
    width: 60px;
  }
}
.pay-lic-poke {
  left: 0;
}

.customize-table-btn {
  position: relative;
  width: 32px;
  overflow: hidden;
  &:hover {
    width: 170px;
  }
}
.customize-table-btn-w {
  width: 170px;
}
.customize-table {
  left: 0;
}

.archive-table-btn {
  position: relative;
  width: 32px;
  overflow: hidden;
  &:hover {
    width: 78px;
  }
}
.archive-table-btn-w {
  width: 78px;
}
.archive-table {
  left: 0;
}

.export-table-btn {
  position: relative;
  width: 32px;
  overflow: hidden;
  &:hover {
    width: 74px;
  }
}
.export-table {
  left: 0;
}

.closing-table-btn {
  position: relative;
  width: 32px;
  overflow: hidden;
  &:hover {
    width: 75px;
  }
}
.closing-table-btn-w {
  width: 75px;
}
.closing-table {
  left: 0;
}
