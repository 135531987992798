.highlighted-btn {
  max-width: fit-content;
  background: #ebebeb;
  border-radius: 155px;
  padding-right: 10px;
  padding-left: 10px;
}

.request-status {
  vertical-align: middle;
}
.status-icon {
  position: relative;
  display: inline-block;
}

.status-hover {
  display: none;
  position: absolute;
  color: #fff;
  background: rgba(0, 0, 0, 0.54);
  border-radius: 4px;
  min-width: 2.5rem;
  line-height: 1.6rem;
  padding: 0.2rem 0.4rem;
  text-align: center;
  font-weight: 700;
  font-size: 0.7rem;
  z-index: 100;

  &--request {
    min-width: 5rem;
  }
}

.status-icon:hover .status-hover {
  display: block;
}
