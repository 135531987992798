.money-req-reject-btn {
  position: relative;
  width: 12px;
  overflow: hidden;
  &:hover {
    width: 52px;
  }
}
.money-req-reject {
  left: 0;
}

.money-req-accept-btn {
  position: relative;
  width: 12px;
  overflow: hidden;
  &:hover {
    width: 50px;
  }
}
.money-req-accept {
  left: 3px;
}
