.sidebar-container {
  padding-top: 50px;
  margin-top: -50px;
  height: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }
}

.sidebar-container::-webkit-scrollbar {
  display: none;
  width: 10px;
  height: 10px;
}

.sidebar-container::-webkit-scrollbar-track {
  background: #f8fafc;
  border-radius: 8px 0 0 8px;
}

.sidebar-container::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border: 1px solid #e7f5ff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.change-language {
  width: 100%;
  height: auto;
  padding: 7px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  color: rgb(74, 74, 255);
}
